<template>    
    <div class="portfolio"> 
        <div class="container">
            <div class="row"> 
                <div class="col-12  title  mb-5 mt-5" >
                    {{ project.post_title }}
                </div>
            </div> 
        </div>
    </div>


    <div class="container mt-5 mb-5 project-info" >
        <div class="row">
            <div class="col-12"  >
                <div style="white-space: pre-line;" v-html="project.post_content"></div>
            </div> 
        </div>
    </div>
    

</template>
  
<script>
   
import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);

export default {
    data(){
        return{
            project:Object,
            data:[],
        }
    },
    
    setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };
        return { selectedLanguage, changeLanguage };
	},

    async created(){
        await this.checkCache("https://cms.vivify.pt/wp-json/options/policy"+this.selectedLanguage.lang_url);
        await fetch('https://cms.vivify.pt/wp-json/options/policy'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
        .then(res => res.json()) 
        .then(res =>{
            // console.log(res)
            this.project = res;

            // todo dar valor a this.data
            const description = document.getElementById('meta-description');
            const keywords = document.getElementById('meta-keywords');

            if (description) {
                description.setAttribute('content', this.data.metadescription || '');  
            }
            if (keywords) {
                keywords.setAttribute('content', this.data.metakeywords || ''); 
            }

        });
        this.$parent.$parent.$parent.addtoPercentage(33);
        
    },
    methods:{
        async checkCache(route) {
            if ('caches' in window) {
                const cacheName = 'my-cache-v1'; 
                const cache = await caches.open(cacheName);
                const cachedResponse = await cache.match(route);
const isCached = !!cachedResponse;
                if(!isCached) this.$parent.$parent.$parent.resetFlag();            }
        },
    }
}
</script>

<style>

</style>