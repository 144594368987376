<template>
<div class="container">
    <div v-if="done" class="row culture mt-md-5">
        <div class="col-12 col-lg-7 bring-front">
            <div class="row">
                <div class="col-12 my-5">
                    <span class="title">{{data.focus[0].title_3}}</span>
                </div>
                <div class="col-12 culture-text">
                    <p class="text mb-4 our-mission-text">
                        {{data.focus[0].text_1}}
                    </p>
                    <p class="text our-mission-text">
                        {{data.focus[0].text_2}}
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-5 text-center bring-front mt-5 fix-dots">
            <n-carousel :slides-per-view="1" autoplay draggable :show-dots="true"> 
                <div v-for="team_pic in data.team_pics" style="position: relative;">
                    <img loading="lazy" class="team-pic" :src="team_pic.image" alt="Equipa Vivify">
                </div> 
            </n-carousel>
            <div style="width: 100%; height: 50px;"></div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12" style="padding: 0;">
                        
            <div v-if="done" class=" culture light-gray-background py-5 mt-5">
            
                <div class="container">
                    <div class="row">
        <!-- <div class="col-12" style="padding: 0;"> -->
            <!-- <div class="container"> --> 
                    <img loading="lazy" class="background_left_v" src="background_v.svg" alt="Logo Vivify"> 

                    <div class="col-12 col-lg-6 d-none d-lg-flex justify-content-center align-items-center bring-front"> 
                        <h1 class="title title-our">{{data.focus[0].title_1}}<br><span class="big-text">{{data.focus[0].title_2}}</span></h1>
                    </div> 
                    <div class="col-12 col-lg-6 d-flex d-lg-none align-items-center bring-front">  
                        <h1 class="title">{{data.focus[0].title_1}}<br><span class="big-text">{{data.focus[0].title_2}}a</span></h1>
                    </div> 

                    <div class="col-12 col-lg-6 bring-front"> 
                        <div v-for="content in data.focus[0].content">
                            <h4 class="gray-text">{{content.title}}</h4>
                            <p class="text gray-text mb-5 mt-3">{{content.text}}</p>
                        </div>
                    </div>
                <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--* ----------------- Blog Articles ----------------- *-->
<!--             
<div class="container ">
    <div class="row culture blog-carousel my-5">
        <div class="col-12 bring-front mb-4"> 
            <h3 class="gray-text">{{data.events_slide_title}}</h3>
        </div> 
        
        <div class="col-12 bring-front mb-4"> 
             
            <n-carousel :slides-per-view="tab_width" draggable style="place-items: center;" >
 
                 
                <div v-for="blog in data_blogs" class="carousel-border mx-1" style="position: relative;"> 
                    <RouterLink :to="{ name: 'Blog', params: { id: blog.id } }">
                        <img loading="lazy" class="carousel-img" :src="blog.img" :alt="blog.title">
                        <div class="carousel-image-overlay d-flex">
                             
                            <span class=" my-auto mx-auto text-center">{{ blog.title }}</span> 
                        </div>
                    </RouterLink>
                </div>
  
                <template #dots="{ total, currentIndex, to }">
                    <ul class="custom-dots">
                        <li
                        v-for="index of total"
                        :key="index"
                        :class="{ ['is-active']: currentIndex === index - 1 }"
                        @click="to(index - 1)"
                        ></li>
                    </ul>
                </template>

            </n-carousel> 

        </div>
    </div> 

</div>
-->
<!--* ----------------- Blog Articles ----------------- *-->
 
  
    <challenge_vue v-if="done" :title="data.challenge[0].title" :text="data.challenge[0].text" :button="data.challenge[0].button" :challenge_background_image="data.challenge[0].challenge_background_image"></challenge_vue>
     

    
</template>
  
<script>

import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
   
import challenge_vue from '../../components/common/challenge'
export default {
    components:{challenge_vue},

    data(){
        return{
            data:[], 
            data_blogs:[], 
            done:null,
            tab_width: window.innerWidth < 720 ? 1 : window.innerWidth < 1024 ? 2 : window.innerWidth< 1200 ? 3 :4 ,
        }
    },

    mounted() {
        window.addEventListener("resize", this.updateTabWidth);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateTabWidth);
    },
    setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };
        return { selectedLanguage, changeLanguage };
	},

    async created(){
        await this.checkCache('https://cms.vivify.pt/wp-json/options/culture'+this.selectedLanguage.lang_url);

        await fetch('https://cms.vivify.pt/wp-json/options/culture'+this.selectedLanguage.lang_url)
            .then(res => res.json()) 
            .then(res =>{
                this.data=res['culture'][0];
                this.data_blogs=res['blogs'];
                // this.data=res[0];
                this.done=true;
 
                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');

                if (description) {
                    description.setAttribute('content', this.data.metadescription || ''); // Fallback vazio
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data.metakeywords || ''); // Fallback vazio
                }

                // console.log(res[0]);
                // console.log(res['culture']);
                // console.log(this.data);
            })

        this.$parent.$parent.$parent.addtoPercentage(33);

    },
    methods:{
        async checkCache(route) {
            if ('caches' in window) {
                const cacheName = 'my-cache-v1'; 
                const cache = await caches.open(cacheName);
                const cachedResponse = await cache.match(route);
const isCached = !!cachedResponse;
                if(!isCached) this.$parent.$parent.$parent.resetFlag();            }
        },
        formatText(txt){
            if(txt==undefined || txt=='')return '';
            return txt.replace(/\. /g, '.<br>');
        },

        updateTabWidth() {
            this.tab_width = window.innerWidth < 768 ? 1 : window.innerWidth < 1024 ? 2 : window.innerWidth< 1200 ? 3 :4 ;
        },
    }
}
</script>

<style>

</style>