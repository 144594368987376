<template>
    
    <div v-if="done" style="width:100%;height:100%;position:relative; background-size: cover;background-repeat: no-repeat;">
        <div class="blue-filter"></div>
        <div class="container">
            <div class="row services py-5">
                <div class="col-12 col-sm-9 title bring-front" >
                    {{cmms.titulo}}
                </div>
                <div class="col-12 col-sm-8 small-title bring-front">
                    {{cmms.descricao}}
                </div>
                
                <div class="col-7 d-none d-sm-block">
                </div>
            </div> 
        </div> 
    </div>
        
    <div class="cmms" v-if="done">
        <div class="container py-5">
            <div class="row">
                <div class="col-12 text-center">
                    <img loading="lazy" :src="cmms.image" class="w-100 cmms-main-image" alt="Demo">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <center>
                        <n-carousel :slides-per-view="tab_width_1" autoplay draggable :show-dots="false"> 
                            <h4 v-for="k in cmms.keys" class="key-points-cmms">{{ k.key }}</h4>
                        </n-carousel>
                    </center>
                </div>
            </div>
            <div class="row  mt-4">
                <div class="col-12 col-lg-6 align-content-center concept" >
                    <div class="title mb-3">
                        {{ cmms.integracoes_erp.titulo }}
                    </div>
                    <div class="content" v-html="cmms.integracoes_erp.descricao"></div>
                </div>

                <div class="col-12 col-lg-6 align-content-center">
                    <div class="row">
                        <div v-for="int in cmms.integracoes_erp.integracoes" class="col-12 col-sm-6 col-md-6 col-lg-6 cmms-integration-list">
                            <img :src="int.logotipo" :alt="int.descricao" :title="int.descricao">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tools">
            <div class="container">
                <div v-if="done" class="content cmms">
                    <div class="title">
                        {{ cmms.titulo_tools }}
                    </div>
                    <div class="subtitle">
                        {{ cmms.descricao_tools }}
                    </div>
                    <div class="row tool">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 containeer" v-for="t of cmms.tools">
                            <span>{{ t.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="side-by-side-div">
            <div v-for="(feature, index) in cmms.features" :key="index" class="row"> 
                <div :style="{ background: getGradientColor(index, feature) }" class="w-100">
                    <div class="container">
                        <div class="row"> 
                            <div
                                :class="{
                                    'order-last': index % 2 === 1 && isLgScreen,
                                    'order-first': index % 2 === 0 && isLgScreen
                                }"
                                class="col-12 col-lg-6 p-5 side-by-side-color-col"
                                :style="{ color: feature.text_color }"
                            >
                                <div ref="chart" :style="{ width: '100%', height: '100%',position:'relative' }"></div>
                            </div>
                            <div
                                :class="{
                                    'order-first': index % 2 === 1 && isLgScreen,
                                    'order-last': index % 2 === 0 && isLgScreen
                                }"
                                class="col-12 col-lg-6 p-5 side-by-side-color-col"
                                style="min-height: 400px;"
                                :style="{ color: feature.text_color }"
                            >
                            <div v-html="feature.content"></div>
                            </div>
                        </div>
                        <div class="row" v-if="index < cmms.features.length-1">
                            <hr class="cmms-line">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid light-gray-background py-5" style="padding-left: 0; padding-right: 0;">
            <div v-if="done" class="container demo-form-container">  
                <div class="row my-3"> 
                    <div class="d-none d-md-none d-lg-block col-12 col-lg-6 pe-lg-5 form-side-img"   >
                        <img loading="lazy" :src="demo_form.form_image" alt="Imagem Form">
                    </div>
                    <div v-if="done" class="col-12 col-lg-6 form-challenge ps-lg-5" > 
                        <div class="title d-sm-block">{{demo_form.form_title}}<span>.</span></div>
                        
                        <div class="row mt-3">
                            <div class="col-12 col-md-6 py-1">
                                <input v-model="nome" type="text" :placeholder="demo_form.name" :class="{error:errors.includes('nome')}" :disabled="loading">
                            </div>
                            <div class="col-12 col-md-6 py-1">
                                <input v-model="email" type="email" :placeholder="demo_form.email" :class="{error:errors.includes('email')}" :disabled="loading">
                            </div>
                            <div class="col-12 py-1">
                                <input v-model="empresa" type="text" :placeholder="demo_form.company" :class="{error:errors.includes('empresa')}" :disabled="loading">
                            </div>
                            <div class="col-12 py-1 mt-2"> 
                                <div class="row p-0">
                                    <div  :class="{error:errors.includes('product_demo')}" class="col-12 demo-select-title mb-2" style="border: 0 !important;">{{demo_form.project_demo_title}}</div> 
                                    
                                    <div class="col-12 mb-2" >
                                        <select v-model="product_demo" :class="{error:errors.includes('product_demo')}">
                                            <option v-for="project_d in projects_demo" :value="project_d.name">{{ project_d.name}}</option>
                                        </select>
                                    </div> 
                                </div> 
                            </div>
                            <div class="col-12 py-1">
                                <textarea v-model="mensagem" :placeholder="demo_form.message" :class="{error:errors.includes('mensagem')}" :disabled="loading"></textarea>
                            </div> 
        
                            <div class="col-12 mt-2">
                                <div class="row">
                                    <div class="d-none d-sm-block col-sm-7 col-xl-8"></div>
                                    <div class="col-12 col-sm-5 col-xl-4">
                                        <button @click="recaptcha" class="submit" :disabled="loading">
                                            <span v-if="!loading">{{demo_form.submit}}</span>
                                            <span v-else><n-spin size="small"/></span>
                                        </button>
                                    </div>  
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </div>
</template>
  
<script>
import { useNotification } from 'naive-ui' 
import { useReCaptcha } from "vue-recaptcha-v3";
import { useLanguageStore } from '../stores/language';
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import fetchRetry from 'fetch-retry';
import * as echarts from 'echarts';

const fetch = fetchRetry(global.fetch);

export default {
    components:{},
    data(){
        return{ 
            concept:[],
            cmms: null,
            done: false, 
            banner:null,
            preditive:null,
            solutions:null,
            tab_width_1: window.innerWidth <= 425 ? 2 : 3,
            isLgScreen: window.innerWidth >= 992,
            tab_width_2: window.innerWidth <= 768 ? 1 : window.innerWidth <= 992 ? 2 : 3,
            demo_form:null,
            nome: '',
            email: '',
            empresa: '',
            mensagem: '',
            errors: [],
            loading:false,
            charts:[],
            projects_demo:[],
            products: [],
            product_demo:null,
            services:[],
            services_selected:[],
        }
    },

    async created(){
        await this.checkCache('https://cms.vivify.pt/wp-json/options/cmms'+this.selectedLanguage.lang_url);

        await fetch('https://cms.vivify.pt/wp-json/options/cmms'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>
            {
                this.cmms=res['cmms'][0];
                this.banner = res['cmms'][0].banner[0];
                this.demo_form = res['cmms'][0].demo_form;
                this.projects_demo = this.demo_form['projects_demo']; 
                this.done = true;

                
                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');
                // todo não é data
                if (description) {
                    description.setAttribute('content', this.data.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data.metakeywords || ''); 
                }
        })

        this.$parent.$parent.$parent.addtoPercentage(33);
        this.initChart1();
        this.initChart2();
        this.initChart3();
    },

    setup(){
        
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };

        const notification = useNotification()
        return {
            error(content,title) {
                notification.error({
                    title:title,
                    content: content
                })
            },
            success(content,title) {
                notification.success({
                    title:title,
                    content: content
                })
            },
            selectedLanguage, changeLanguage
        }
    },


    mounted() {
        // window.addEventListener('resize', this.resizeChart);
        window.addEventListener('resize', this.updateScreenSize); 
        window.addEventListener("resize", this.updateTabWidth);
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            this.recaptcha = async () => {
                await recaptchaLoaded()
                this.token = await executeRecaptcha('login')
                this.submitForm();
            }
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);  
        window.removeEventListener("resize", this.updateTabWidth);
    },

    methods: { 
        initChart1(){
            let title = '';
            let data = [];
            switch(this.selectedLanguage.lang){
                case 'pt':
                    title = 'Segmentação de manutenções';
                    data = [
                        { value: 1048, name: 'Corretiva',itemStyle: {color: '#dbf2f2'} },
                        { value: 735, name: 'Preventiva',itemStyle: {color: '#ffe0e6'} },
                        { value: 580, name: 'Preditiva',itemStyle: {color: '#ebe0ff'} },
                        { value: 484, name: 'Decreto-Lei 50/2005',itemStyle: {color: '#ffedcd'} },
                    ];
                    break;
                case 'en':
                   
                    break;
                case 'es':
                   
                    break;
            }
            let chart = echarts.init(this.$refs.chart[0]);
            let options = {
                title: [
                    {
                        text: title,
                        left: 'center',
                        padding:[0,0,0,10]
                    }
                ],
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: title,
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        padAngle: 5,
                        itemStyle: {
                            borderRadius: 10
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 20,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            };
            chart.setOption(options);
            this.charts.push(chart);
        }, 
        initChart2(){
            let title = '';
            let datax = []
            let datay = [];
            switch(this.selectedLanguage.lang){
                case 'pt':
                    title = 'Gestão de equipas';
                    datax = ['Mecânico', 'Elétrico', 'Automação','Instrumentista'];
                    datay = [{data: [120, 200, 150, 80],stack: 'total',type: 'bar',name:'Finalizado',label: {show: true,},itemStyle: {color: '#dbf2f2'}},{data: [20, 10, 8, 4],stack: 'total',type: 'bar',name:'Em aberto',label: {show: true,},itemStyle: {color: '#ffe0e6'}},]
                    break;
                case 'en':
                    
                    break;
                case 'es':
                    
                    break;
            }
            let chart2 = echarts.init(this.$refs.chart[1]);
            let options2 = {
                title: [
                    {
                        text: title,
                        left: 'center',
                        padding:[0,0,0,10]
                    }
                ],
                legend: {
                    top: '5%',
                    left: 'center'
                },
                xAxis: {
                    type: 'category',
                    data: datax
                },
                yAxis: {
                    type: 'value'
                },
                series: datay,
                barWidth:'33%',

            };
            chart2.setOption(options2);
            this.charts.push(chart2);
        },
        initChart3(){
            let title = '';
            let datax = []
            let datay = [];
            switch(this.selectedLanguage.lang){
                case 'pt':
                    title = 'Custo mensal por tipo de manutenção';
                    datax = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];
                    datay = [
                    {
                    name: 'Corretiva',
                    type: 'bar',
                    barGap: 0,
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {color: '#dbf2f2'},
                    data: [320, 332, 301, 334, 390,320, 332, 301, 334, 390,320, 332, 301, 334]
                    },
                    {
                    name: 'Preventiva',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {color: '#ffe0e6'},
                    data: [220, 182, 191, 234, 290,220, 182, 191, 234, 290,220, 182, 191]
                    },
                    {
                    name: 'Preditiva',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {color: '#ebe0ff'},
                    data: [150, 232, 201, 154, 190,150, 232, 201, 154, 190,150, 232, 201]
                    },
                    {
                    name: 'Decreto-Lei 50/2005',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {color: '#ffedcd'},
                    data: [98, 77, 101, 99, 40,98, 77, 101, 99, 40,98, 77, 101]
                    }
                ]
                    break;
                case 'en':
                    
                    break;
                case 'es':
                    
                    break;
            }
            let chart3 = echarts.init(this.$refs.chart[2]);
            let options3 = {
                title: [
                    {
                        text: title,
                        left: 'center',
                        padding:[0,0,0,10]
                    }
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                xAxis: [
                    {
                    type: 'category',
                    axisTick: { show: false },
                    data: datax
                    }
                ],
                yAxis: [
                    {
                    type: 'value'
                    }
                ],
                series: datay
            };
            chart3.setOption(options3);
            this.charts.push(chart3);
        },
        async checkCache(route) {
            if ('caches' in window) {
                const cacheName = 'my-cache-v1'; 
                const cache = await caches.open(cacheName);
                const cachedResponse = await cache.match(route);
                const isCached = !!cachedResponse;
                if(!isCached) this.$parent.$parent.$parent.resetFlag();            
            }
        },
        filter(f) {
            // console.log(f)
            let target = event.target.parentElement;
            target.classList.toggle('active');

            if(target.classList.value.includes('active')){
                this.services_selected.push(f);
            }else{
                this.services_selected.splice(this.services_selected.indexOf(f),1);
            }       
        },
        getNames(){
            let names = []
            this.services_selected.forEach(element => {
                names.push(this.services.find(s => s.id == element).name);
            });
            return names.join(',');
        }, 
        async submitForm() {
            if(this.checkForm()){
                this.loading=true;
                let services_selected_names = this.getNames();

                $('#error_submit').hide();
 
                await fetch('https://cms.vivify.pt/wp-json/options/send-email-project',{
                    method:'POST',
                    headers: {
                        'Content-Type': 'application/json',  // Ensure the content type is JSON
                    },
                    body:JSON.stringify(
                        {
                            token:this.token,
                            subject:'PEDIDO DE DEMO',
                            message:'EMPRESA :'+this.empresa+"\nNOME: "+this.nome+"\nEMAIL: "+this.email+"\nMENSAGEM: "+this.mensagem+"\nSERVIÇOS: "+this.product_demo 
                        }
                    ) 
                })
                .then(res =>{
                    if(res.status==200){
                        this.success('Obrigado pelo seu contacto!','ENVIADO COM SUCESSO'); 

                        this.resetForm();
                        this.date = null;
                    }else{
                        this.error('Erro ao enviar pedido de contacto','TENTE MAIS TARDE') 

                    }
                    this.loading=false; 
                })
            } 
        },
        resetForm(){
            this.empresa=null;
            this.nome=null;
            this.telefone=null;
            this.email=null;
            this.mensagem=null;
            this.contact=null;
            // this.terms=false; 
            this.product_demo = null;
        },
        checkForm(){
            // nome, email, empresa e fale-nos, são obrigatórios
            this.errors=[];
            if(!this.validEmail(this.email))this.errors.push('email'); 
            if(this.empresa=='' || this.empresa==null)this.errors.push('empresa');
            if(this.nome=='' || this.nome==null)this.errors.push('nome'); 
            if(this.mensagem=='' || this.mensagem==null)this.errors.push('mensagem'); 
            if (!this.product_demo) this.errors.push('product_demo'); 

            if(this.errors.length>=1)return false;
            return true;
        },
        validEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        getGradientColor(index, feature) {
            const isLgScreen = window.innerWidth >= 992;
            const direction = isLgScreen ? 'to right' : 'to bottom';

            let background_color;
            let background_color_2;

            if (isLgScreen) {
                background_color = feature.background_color;
                background_color_2 = feature.background_color_2;
            } else {
                background_color = feature.background_color_2;
                background_color_2 = feature.background_color;
            }
             
            const isEvenIndex = index % 2 === 0;
            const color1 = (isEvenIndex && !isLgScreen) ? background_color : background_color_2;
            const color2 = (isEvenIndex && !isLgScreen)  ? background_color_2 : background_color;

            return `linear-gradient(${direction}, ${color1} 50%, ${color2} 50%)`;
        },


        updateTabWidth() {
            this.tab_width_1 = window.innerWidth <= 425 ? 2 : 3;
            this.tab_width_2 = window.innerWidth <= 768 ? 1 : window.innerWidth <= 992 ? 2 : 3;
        },

        updateScreenSize() {
            this.isLgScreen = window.innerWidth >= 992; // Atualiza o estado
        },
    
    }
}
</script>

<style>

</style>