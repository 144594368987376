<template>
    <div>
        <div style="width:100%;height:100%;position:relative; background-size: cover;background-repeat: no-repeat;" :style="{ 'background-image': 'url(' + banner.image_1 + ')' }">
            <div class="blue-filter"></div>
            <div class="container">
                <div class="row services py-5">
                    <div class="col-12 col-sm-9 title bring-front" >
                        {{banner.titulo}}
                    </div>
                    <div class="col-12 col-sm-8 small-title bring-front">
                        {{banner.descricao}}
                    </div>
                    
                    <div class="col-7 d-none d-sm-block">
                    </div>
                </div> 
            </div> 
        </div>
    </div>

    <div class="container">
        <div v-if="done" class="row culture mt-md-5">
            <div class="col-12 col-lg-6 bring-front">
                <div class="industry-intro">
                    <div v-html="data.challenge.descricao" class="text"></div>
                </div>
            </div>

            <div class="col-12 col-lg-6 bring-front">
                <div class="industry-items">
                    <ul>
                        <li v-for="i in data.challenge.vertentes">
                            <h3>{{ i.item }}</h3>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container ">
        <div v-if="done" class="row culture blog-carousel my-5">
            <div class="col-12 bring-front mb-4"> 
                <h3 class="gray-text">{{data.events_slide_title}}</h3>
            </div> 
            <div class="col-12 bring-front mb-4"> 
                <n-carousel :slides-per-view="tab_width" draggable style="place-items: center;" >
                    <div v-for="prod in data.challenge.produtos" class="carousel-border mx-1 industrial-proj-slide" style="position: relative;"> 
                        
                        <div class="image industrial">
                            <div class="image-container">
                                <img loading="lazy" :src="prod.imagem_de_capa" :alt="prod.nome">
                            </div>
                            <div class="content d-flex">
                                <span class="title my-auto mx-auto" style="text-transform: uppercase">{{prod.descricao}}</span>
                            </div>
                        </div>
                    </div>
                    <template #dots="{ total, currentIndex, to }">
                        <ul class="custom-dots">
                            <li
                            v-for="index of total"
                            :key="index"
                            :class="{ ['is-active']: currentIndex === index - 1 }"
                            @click="to(index - 1)"
                            ></li>
                        </ul>
                    </template>
                </n-carousel> 
            </div>
        </div> 
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-12" style="padding: 0;">
                <div v-if="done" class=" culture light-gray-background py-5 mt-5">
                    <div class="container">
                        <div class="row">
                            <img loading="lazy" class="background_left_v" src="background_v.svg" alt="Logo Vivify">
                            <div class="col-12 col-lg-6 d-none d-lg-flex justify-content-center align-items-center bring-front"> 
                                <h1 class="title title-our">{{data.challenge.integracoes.titulo}}</h1>
                            </div> 
                            <div class="col-12 col-lg-6"></div>
                            <div class="col-12 col-xl-6">
                                <!-- DESKTOP -->
                                <img loading="lazy" class="d-none d-xl-block balance" style="max-width: 400px !important;" :src="data.challenge.integracoes.imagem" :alt="data.challenge.integracoes.titulo">
                                <!-- TABLE/MOBILE -->
                                <div class="d-block d-xl-none w-100 question-mark" style="position: relative;height: 4em;">
                                    <transition name="fade">
                                        <svg v-if="!integrators_flag" @click="integrators_flag=true;" style="position:absolute;color:white;top:0;right: 0;" xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" fill="currentColor" class="d-block d-xl-none bi bi-question-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                                        </svg>
                                        <svg v-else @click="integrators_flag=false;" style="position:absolute;color:white;top:0;right: 0;" xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" fill="currentColor" class="d-block d-xl-none bi bi-x-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                        </svg>
                                    </transition>
                                </div>
                                <div class="d-block d-xl-none w-100 home-container-integrator">
                                    <transition name="fade">
                                        <img loading="lazy" :alt="data.challenge.integracoes.titulo" style="padding-left: 10%;padding-right: 10%;position: absolute;top:0;left:0" key="first" v-if="!integrators_flag" class="home-image-integrators w-100" :src="data.challenge.integracoes.imagem">
                                        <n-carousel style="color:white;position: absolute;top:0;left:0" key="second" v-else :slides-per-view="1"  draggable :show-dots="false" show-arrow >  
                                            <n-carousel-item class="py-5"  v-for="i in data.challenge.integracoes['itot']"> 
                                                <span class="title">{{ i.titulo }}</span>
                                                <div v-html="i.descricao" class="integration-description"></div>
                                            </n-carousel-item>
                                        </n-carousel>
                                    </transition>
                                </div>
                            </div>
                            <div class="d-none d-xl-block col-6">
                                <n-carousel :slides-per-view="1" draggable :show-dots="false" show-arrow style="color:white" class="integration-slide">  
                                    <n-carousel-item class="py-5"  v-for="i in data.challenge.integracoes['itot']"> 
                                        <span class="title">{{ i.titulo }}</span>
                                        <div v-html="i.descricao" class="integration-description"></div>
                                    </n-carousel-item>
                                </n-carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="tools">
        <div class="container">
            <div v-if="done" class="content">
                <div class="title">
                    {{ data.challenge.titulo_tools }}
                </div>
                <div class="subtitle">
                    {{ data.challenge.descricao_tools }}
                </div>
                <div class="row tool">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 containeer" v-for="t of data.challenge.tools">
                        <span>{{ t.name }}</span>
                        <div class="group">
                            <img loading="lazy" style="max-height: 50px; width: 50px;" :src="to.logo.url" v-for="to of t.tool" :title="to.alt">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid light-gray-background py-5" style="padding-left: 0; padding-right: 0;">
        <div v-if="done" class="container demo-form-container">  
            <div class="row my-3"> 
                <div class="d-none d-md-none d-lg-block col-12 col-lg-6 pe-lg-5 form-side-img"   >
                    <img loading="lazy" :src="demo_form.form_image" alt="Imagem Form">
                </div>
                <div v-if="done" class="col-12 col-lg-6 form-challenge ps-lg-5" > 
                    <div class="title d-sm-block">{{demo_form.form_title}}<span>.</span></div>
                    
                    <div class="row mt-3">
                        <div class="col-12 col-md-6 py-1">
                            <input v-model="nome" type="text" :placeholder="demo_form.name" :class="{error:errors.includes('nome')}" :disabled="loading">
                        </div>
                        <div class="col-12 col-md-6 py-1">
                            <input v-model="email" type="email" :placeholder="demo_form.email" :class="{error:errors.includes('email')}" :disabled="loading">
                        </div>
                        <div class="col-12 py-1">
                            <input v-model="empresa" type="text" :placeholder="demo_form.company" :class="{error:errors.includes('empresa')}" :disabled="loading">
                        </div>
                        <div class="col-12 py-1 mt-2"> 
                            <div class="row p-0">
                                <div  :class="{error:errors.includes('product_demo')}" class="col-12 demo-select-title mb-2" style="border: 0 !important;">{{demo_form.project_demo_title}}</div> 
                                
                                <div class="col-12 mb-2" >
                                    <select v-model="product_demo" :class="{error:errors.includes('product_demo')}">
                                        <option v-for="project_d in projects_demo" :value="project_d.name">{{ project_d.name }}</option>
                                    </select>
                                </div> 
                            </div> 
                        </div>
                        <div class="col-12 py-1">
                            <textarea v-model="mensagem" :placeholder="demo_form.message" :class="{error:errors.includes('mensagem')}" :disabled="loading"></textarea>
                        </div> 
    
                        <div class="col-12 mt-2">
                            <div class="row">
                                <div class="d-none d-sm-block col-sm-7 col-xl-8"></div>
                                <div class="col-12 col-sm-5 col-xl-4">
                                    <button @click="recaptcha" class="submit" :disabled="loading">
                                        <span v-if="!loading">{{demo_form.submit}}</span>
                                        <span v-else><n-spin size="small"/></span>
                                    </button>
                                </div>  
                            </div>
                        </div> 
                    </div>
                </div>
            </div> 
        </div> 
    </div>

    
    <challenge_vue v-if="done" :title="data.challenge.challenge.title" :text="data.challenge.challenge.text" :button="data.challenge.challenge.button"  :challenge_background_image="data.challenge.challenge.challenge_background_image"></challenge_vue>
 

</template>
  
<script>
  
import challenge_vue from '../../components/common/challenge';
import { useReCaptcha } from "vue-recaptcha-v3";
import { useNotification } from 'naive-ui';
import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);

export default {
    components:{challenge_vue},
    data(){
        return{
            data:[],
            banner:[],
            areas:[],
            collapse:[],
            refresh:true,
            demo_form:[],
            loading:false, 
            services:[],
            services_selected:[],

            projects_demo:[],

            done:false,
            errors:[],

            products: [],

            product_demo:null,
            tab_width: window.innerWidth < 720 ? 1 : window.innerWidth < 1024 ? 2 : window.innerWidth< 1200 ? 3 :3 ,
        }
    },
 
    async created(){
        // await this.checkCache('https://cms.vivify.pt/wp-json/options/industry'+this.selectedLanguage.lang_url);
        this.$parent.$parent.$parent.resetFlag()
        await fetch('https://cms.vivify.pt/wp-json/options/industry'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>{
                // console.log('start');
                
                this.data=res;
 
                this.banner = res['challenge']['banner_principal'][0];
                this.areas.push(res['challenge']['shiftpro']);
                this.areas.push(res['challenge']['manutencao_cmms']);
                this.areas.push(res['challenge']['iot']);
                this.areas.push(res['challenge']['outras_solucoes']);

                
                this.services=res['categories']; 
                this.demo_form = res['challenge']['demo_form']; 
                this.projects_demo = this.demo_form['projects_demo']; 

                // console.log(this.areas);
                //this.collapse=Array(this.data.topics.length).fill().map((_, i) => ''+i+'')
                this.refresh=false;
                setTimeout(() =>this.refresh=true , 250)

                this.done=true; 
  
                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');

                if (description) {
                    description.setAttribute('content', this.data.challenge.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data.challenge.metakeywords || ''); 
                }
            })

        this.$parent.$parent.$parent.addtoPercentage(33);
    },

    
    mounted(){
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
        this.recaptcha = async () => 
        {
            await recaptchaLoaded()
            this.token = await executeRecaptcha('login')
            this.submitForm();
        }
    },
 
    setup(){
        const notification = useNotification();
        
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        }; 

        return {
            error(content,title) {
                notification.error({
                    title:title,
                    content: content
                })
            },
            success(content,title) {
                notification.success({
                    title:title,
                    content: content
                })
            },
            selectedLanguage, changeLanguage
        }
    },

    methods:{
        async checkCache(route) {
            if ('caches' in window) {
                const cacheName = 'my-cache-v1'; 
                const cache = await caches.open(cacheName);
                const cachedResponse = await cache.match(route);
                const isCached = !!cachedResponse;
                if(!isCached) this.$parent.$parent.$parent.resetFlag();
            }
        },
        formatText(txt){
            if(txt==undefined || txt=='')return '';
            return txt.replace(/\. /g, '.<br>');
        },

 
        filter(f) {
            // console.log(f)
            let target = event.target.parentElement;
            target.classList.toggle('active');

            if(target.classList.value.includes('active')){
                this.services_selected.push(f);
            }else{
                this.services_selected.splice(this.services_selected.indexOf(f),1);
            }       
        },
        getNames(){
            let names = []
            this.services_selected.forEach(element => {
                names.push(this.services.find(s => s.id == element).name);
            });
            return names.join(',');
        }, 
        async submitForm() {
            if(this.checkForm()){
                this.loading=true;
                let services_selected_names = this.getNames();

                $('#error_submit').hide();
 
                await fetch('https://cms.vivify.pt/wp-json/options/send-email-project',{
                    method:'POST',
                    headers: {
                        'Content-Type': 'application/json',  // Ensure the content type is JSON
                    },
                    body:JSON.stringify(
                        {
                            token:this.token,
                            subject:'PEDIDO DE DEMO',
                            message:'EMPRESA :'+this.empresa+"\nNOME: "+this.nome+"\nEMAIL: "+this.email+"\nMENSAGEM: "+this.mensagem+"\nSERVIÇOS: "+this.product_demo 
                        }
                    ) 
                })
                .then(res =>{
                    if(res.status==200){
                        this.success('Proposta enviada com sucesso!','ENVIADO COM SUCESSO'); 

                        this.resetForm();
                        this.date = null;
                    }else{
                        this.error('Erro ao enviar Proposta','ENVIADO SEM SUCESSO') 

                    }
                    this.loading=false; 
                })
            } 
        },
        resetForm(){
            this.empresa=null;
            this.nome=null;
            this.telefone=null;
            this.email=null;
            this.mensagem=null;
            this.contact=null;
            // this.terms=false; 
            this.product_demo = null;
        },
        checkForm(){
            // nome, email, empresa e fale-nos, são obrigatórios
            this.errors=[];
            if(!this.validEmail(this.email))this.errors.push('email'); 
            if(this.empresa=='' || this.empresa==null)this.errors.push('empresa');
            if(this.nome=='' || this.nome==null)this.errors.push('nome'); 
            if(this.mensagem=='' || this.mensagem==null)this.errors.push('mensagem'); 
            if (!this.product_demo) this.errors.push('product_demo'); 

            if(this.errors.length>=1)return false;
            return true;
        },
        validEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }



    }
}
</script>

<style>

</style>