<template>

    <div v-if="done" class="container-fluid iot-container">
        <div class="row">
            <div class="container">
                <div class="row services py-5">
                    <div class="col-12 col-sm-9 title bring-front" >
                        {{iot.titulo}}
                    </div>
                    <div class="col-12 col-sm-8 small-title bring-front">
                        {{iot.descricao}}
                    </div>
                    
                    <div class="col-7 d-none d-sm-block">
                    </div>
                </div> 
            </div> 
            <div class="container py-5">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="iot-demo-machine">
                            <img loading="lazy" :src="iot.imagem_maquina" class="w-100" alt="IIoT VIVIFY">
                            <div class="status-machine"></div>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="row">
                            <div class="col-6 col-sm-4" v-for="(indicador,index) in iot.iot_indicadores">
                                <div class="iot-key" v-if="index!=2">
                                    <h3>{{ indicador.chave }}</h3>
                                </div>
                                <div class="iot-key" v-else>
                                    <h3>{{ tempo_prod_chave }}</h3>
                                </div>
                                <div class="iot-valor" v-if="index!=2">
                                    <span>{{ indicador.valor }}</span>
                                </div>
                                <div class="iot-valor" v-else>
                                    <span>{{ tempo_prod }}</span>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4" >
                                <div class="iot-key">
                                    <h3>{{ produzido_chave }}</h3>
                                </div>
                                <div class="iot-valor">
                                    <span>{{ produzido }} / 1200</span>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4">
                                <div class="iot-key">
                                    <h3>{{ rejeitado_chave }}</h3>
                                </div>
                                <div class="iot-valor">
                                    <span>{{ rejeitado }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="height:200px">
                        <div ref="chart4" :style="{ width: '100%', height: '100%',position:'relative' }"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-12" style="padding: 0;">
                <div v-if="done" class=" culture  py-5 mt-5">
                    <div class="container">
                        <div class="row">
                            <img loading="lazy" class="background_left_v" src="background_v.svg" alt="Logo Vivify">
                            <div class="col-12 col-xl-6 align-items-center">
                                <!-- DESKTOP -->
                                <img loading="lazy" class="d-none d-xl-block balance" style="max-width: 400px !important;" :src="integracoes.imagem" :alt="integracoes.titulo">
                                <!-- TABLE/MOBILE -->
                                <div class="d-block d-xl-none w-100 question-mark" style="position: relative;height: 4em;">
                                    <transition name="fade">
                                        <svg v-if="!integrators_flag" @click="integrators_flag=true;" style="position:absolute;color:white;top:0;right: 0;" xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" fill="currentColor" class="d-block d-xl-none bi bi-question-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                                        </svg>
                                        <svg v-else @click="integrators_flag=false;" style="position:absolute;color:white;top:0;right: 0;" xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" fill="currentColor" class="d-block d-xl-none bi bi-x-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                        </svg>
                                    </transition>
                                </div>
                                <div class="d-block d-xl-none w-100 home-container-integrator">
                                    <transition name="fade">
                                        <img loading="lazy" :alt="integracoes.titulo" style="padding-left: 10%;padding-right: 10%;position: absolute;top:0;left:0" key="first" v-if="!integrators_flag" class="home-image-integrators w-100" :src="integracoes.imagem">
                                        <n-carousel style="color:white;position: absolute;top:0;left:0" key="second" v-else :slides-per-view="1"  draggable :show-dots="false" show-arrow >  
                                            <n-carousel-item class="py-5"  v-for="i in integracoes['itot']"> 
                                                <span class="title">{{ i.titulo }}</span>
                                                <div v-html="i.descricao" class="integration-description"></div>
                                            </n-carousel-item>
                                        </n-carousel>
                                    </transition>
                                </div>
                            </div>
                            <div class="d-none d-xl-block col-6">
                                <n-carousel :slides-per-view="1" draggable :show-dots="false" show-arrow style="color:white" class="integration-slide">  
                                    <n-carousel-item class="py-5"  v-for="i in integracoes['itot']"> 
                                        <span class="title">{{ i.titulo }}</span>
                                        <div v-html="i.descricao" class="integration-description"></div>
                                    </n-carousel-item>
                                </n-carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="done" class="side-by-side-div" style="background-color: white;">
        <div v-for="(feature, index) in iot.features" :key="index" class="row"> 
            <div :style="{ background: getGradientColor(index, feature) }" class="w-100">
                <div class="container">
                    <div class="row"> 
                        <div
                            :class="{
                                'order-last': index % 2 === 1 && isLgScreen,
                                'order-first': index % 2 === 0 && isLgScreen
                            }"
                            class="col-12 col-lg-12 p-5 side-by-side-color-col"
                            :style="{ color: feature.text_color }"
                        >
                            <div ref="chart" :style="{ width: '100%', height: '100%',position:'relative' }"></div>
                        </div>
                    </div>
                    <div class="row" v-if="index < iot.features.length-1">
                        <hr class="cmms-line">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
        
    <div class="cmms" v-if="done">
        <div class="container-fluid light-gray-background py-5" style="padding-left: 0; padding-right: 0;">
            <div v-if="done" class="container demo-form-container">  
                <div class="row my-3"> 
                    <div class="d-none d-md-none d-lg-block col-12 col-lg-6 pe-lg-5 form-side-img"   >
                        <img loading="lazy" :src="demo_form.form_image" alt="Imagem Form">
                    </div>
                    <div v-if="done" class="col-12 col-lg-6 form-challenge ps-lg-5" > 
                        <div class="title d-sm-block">{{demo_form.form_title}}<span>.</span></div>
                        
                        <div class="row mt-3">
                            <div class="col-12 col-md-6 py-1">
                                <input v-model="nome" type="text" :placeholder="demo_form.name" :class="{error:errors.includes('nome')}" :disabled="loading">
                            </div>
                            <div class="col-12 col-md-6 py-1">
                                <input v-model="email" type="email" :placeholder="demo_form.email" :class="{error:errors.includes('email')}" :disabled="loading">
                            </div>
                            <div class="col-12 py-1">
                                <input v-model="empresa" type="text" :placeholder="demo_form.company" :class="{error:errors.includes('empresa')}" :disabled="loading">
                            </div>
                            <div class="col-12 py-1 mt-2"> 
                                <div class="row p-0">
                                    <div  :class="{error:errors.includes('product_demo')}" class="col-12 demo-select-title mb-2" style="border: 0 !important;">{{demo_form.project_demo_title}}</div> 
                                    
                                    <div class="col-12 mb-2" >
                                        <select v-model="product_demo" :class="{error:errors.includes('product_demo')}">
                                            <option v-for="project_d in projects_demo" :value="project_d.name">{{ project_d.name}}</option>
                                        </select>
                                    </div> 
                                </div> 
                            </div>
                            <div class="col-12 py-1">
                                <textarea v-model="mensagem" :placeholder="demo_form.message" :class="{error:errors.includes('mensagem')}" :disabled="loading"></textarea>
                            </div> 
        
                            <div class="col-12 mt-2">
                                <div class="row">
                                    <div class="d-none d-sm-block col-sm-7 col-xl-8"></div>
                                    <div class="col-12 col-sm-5 col-xl-4">
                                        <button @click="recaptcha" class="submit" :disabled="loading">
                                            <span v-if="!loading">{{demo_form.submit}}</span>
                                            <span v-else><n-spin size="small"/></span>
                                        </button>
                                    </div>  
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </div>
</template>
  
<script>
import { useNotification } from 'naive-ui' 
import { useReCaptcha } from "vue-recaptcha-v3";
import { useLanguageStore } from '../stores/language';
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import fetchRetry from 'fetch-retry';
import * as echarts from 'echarts';

const fetch = fetchRetry(global.fetch);

export default {
    components:{},
    data(){
        return{ 
            produzido:0,
            produzido_chave:'',
            produzido_interval:null,
            rejeitado:0,
            rejeitado_chave:'',
            rejeitado_interval:null,
            tempo_prod:'',
            tempo_prod_chave:'',
            tempo_prod_interval:null,
            hours:0,
            minutes:0,
            seconds:0,
            concept:[],
            cmms: null,
            done: false, 
            banner:null,
            preditive:null,
            solutions:null,
            tab_width_1: window.innerWidth <= 425 ? 2 : 3,
            isLgScreen: window.innerWidth >= 992,
            tab_width_2: window.innerWidth <= 768 ? 1 : window.innerWidth <= 992 ? 2 : 3,
            demo_form:null,
            integracoes:null,
            nome: '',
            email: '',
            empresa: '',
            mensagem: '',
            errors: [],
            loading:false,
            charts:[],
            projects_demo:[],
            products: [],
            product_demo:null,
            services:[],
            services_selected:[],
        }
    },

    async created(){
        await this.checkCache('https://cms.vivify.pt/wp-json/options/iot'+this.selectedLanguage.lang_url);

        await fetch('https://cms.vivify.pt/wp-json/options/iot'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>
            {
                this.iot=res['iot'];
                //this.banner = res['cmms'][0].banner[0];
                this.demo_form = this.iot.demo_form;
                this.integracoes = this.iot.integracoes;
                //this.projects_demo = this.demo_form['projects_demo']; 
                this.done = true;
                this.produzido =  parseInt(this.iot.iot_indicadores[4].valor);
                this.rejeitado =  parseInt(this.iot.iot_indicadores[5].valor);
                this.tempo_prod =  this.iot.iot_indicadores[2].valor;
                this.produzido_chave = this.iot.iot_indicadores[4].chave;
                this.rejeitado_chave = this.iot.iot_indicadores[5].chave;
                this.tempo_prod_chave = this.iot.iot_indicadores[2].chave;

                this.iot.iot_indicadores.pop();
                this.iot.iot_indicadores.pop();

                let timeParts = this.tempo_prod.split(":");
                this.hours = parseInt(timeParts[0], 10);
                this.minutes = parseInt(timeParts[1], 10);
                this.seconds = parseInt(timeParts[2], 10);

                this.tempo_prod_interval = setInterval(()=>{
                    this.seconds++; // Increment seconds
                    if (this.seconds === 60) {
                        this.seconds = 0;
                        this.minutes++;
                    }
                    if (this.minutes === 60) {
                        this.minutes = 0;
                        this.hours++;
                    }
                    if (this.hours === 24) {
                        this. hours = 0;
                    }
                    this.tempo_prod = 
                        String(this.hours).padStart(2, "0") + ":" + 
                        String(this.minutes).padStart(2, "0") + ":" + 
                        String(this.seconds).padStart(2, "0");
                },1000)

                this.produzido_interval = setInterval(()=>{
                    this.produzido++;
                },3000)

                this.rejeitado_interval = setInterval(()=>{
                    this.rejeitado++;
                },8000)


                this.tempo_prod_interval = setInterval(()=>{

                },1000);

                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');
                // todo não é data
                if (description) {
                    description.setAttribute('content', this.data.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data.metakeywords || ''); 
                }
        })

        this.$parent.$parent.$parent.addtoPercentage(33);
        //this.initChart1();
        this.initChart2();
        //this.initChart3();
        this.initChart4();
    },

    setup(){
        
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };

        const notification = useNotification()
        return {
            error(content,title) {
                notification.error({
                    title:title,
                    content: content
                })
            },
            success(content,title) {
                notification.success({
                    title:title,
                    content: content
                })
            },
            selectedLanguage, changeLanguage
        }
    },


    mounted() {
        // window.addEventListener('resize', this.resizeChart);
        window.addEventListener('resize', this.updateScreenSize); 
        window.addEventListener("resize", this.updateTabWidth);
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            this.recaptcha = async () => {
                await recaptchaLoaded()
                this.token = await executeRecaptcha('login')
                this.submitForm();
            }
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);  
        window.removeEventListener("resize", this.updateTabWidth);
    },

    methods: { 
        initChart4(){
            let chart = echarts.init(this.$refs.chart4);
            var hours = [];
            var displayHours = [];
            for (let i = 6; i <= 22; i++) {
                for (let j = 0; j < 60; j += 1) {
                    let time = i.toString().padStart(2, '0') + ':' + j.toString().padStart(2, '0');
                    hours.push(time);
                }
                displayHours.push(i.toString().padStart(2, '0') + ':00');
            }

            function getColor(time) {
                let [hour, minute] = time.split(':').map(Number);
                let totalMinutes = hour * 60 + minute;
                
                if (totalMinutes >= 360 && totalMinutes < 420) return '#fdcb6e';
                if (totalMinutes >= 420 && totalMinutes < 510) return '#d63031';
                if (totalMinutes >= 510 && totalMinutes < 555) return '#fdcb6e';
                if (totalMinutes >= 555 && totalMinutes < 780) return '#00b894';
                if (totalMinutes >= 780 && totalMinutes < 790) return '#fdcb6e';
                if (totalMinutes >= 790 && totalMinutes < 820) return '#00b894';
                if (totalMinutes >= 820 && totalMinutes < 830) return '#fdcb6e';
                if (totalMinutes >= 830 && totalMinutes < 840) return '#00b894';
                if (totalMinutes >= 840 && totalMinutes < 855) return '#fdcb6e';
                if (totalMinutes >= 855 && totalMinutes < 865) return '#00b894';
                if (totalMinutes >= 865 && totalMinutes < 900) return '#d63031';
                if (totalMinutes >= 900 && totalMinutes < 915) return '#fdcb6e';
                if (totalMinutes >= 915 && totalMinutes < 950) return '#00b894';
                if (totalMinutes >= 950 && totalMinutes < 975) return '#fdcb6e';
                if (totalMinutes >= 975 && totalMinutes < 1000) return '#00b894';
                if (totalMinutes >= 1000 && totalMinutes < 1100) return '#d63031';
                if (totalMinutes >= 1100 && totalMinutes < 1245) return '#00b894';
                if (totalMinutes >= 1245 && totalMinutes < 1300) return '#fdcb6e';
                if (totalMinutes >= 1300 && totalMinutes < 1400) return '#d63031';
                return 'rgba(0, 0, 0, 0)'; // Default gray
            }

            var data = hours.map(time => ({
                value: 1, // Fixed height
                itemStyle: {
                    color: getColor(time),
                    barBorderWidth: 0,
                    normal: { color:  getColor(time) },
                    emphasis: { color:  getColor(time)}
                }
            }));

            let setup = '';
            let work = '';
            let stop = ''

            switch(this.selectedLanguage.lang){
                case 'pt':
                    setup = 'SETUP';
                    work = 'EM PRODUÇÃO';
                    stop = 'PARADO';
                    break;
                case 'en':
                    setup = 'SETUP';
                    work = 'IN PRODUCTION';
                    stop = 'STOPPED';
                    break;
                case 'es':
                    setup = 'SETUP';
                    work = 'EN PRODUCCIÓN';
                    stop = 'DESMARCADO';
                    break;
            }


            let options = {
                tooltip: {
                    trigger: 'item',  // 'item' means tooltip shows for individual points
                    formatter: function(params){
                        let title = '';
                        switch(params.color){
                            case '#fdcb6e': title = setup; break;
                            case '#d63031': title = stop; break;
                            case '#00b894': title = work; break;
                            default: title = ''; break;
                        }
                        return title;
                    }, // Format to display the name and value
                },
                // title: {
                //     text: 'Colored Bars from 06:00 to 22:00'
                // },
                xAxis: {
                    type: 'category',
                    data: hours,
                    axisLabel: {
                        interval: function(index, value) {
                            return value.endsWith(':00');
                        },
                        rotate: 45
                    },
                    splitLine: {
                        show: false
                    },
                },
                yAxis: {
                    type: 'value',
                    show: false // Hide Y-axis since height is fixed
                },
                series: [{
                    type: 'bar',
                    data: data,
                    barWidth: '100%', // Ensure bars touch each other
                    barGap: '0%', // Remove gaps
                    barCategoryGap: '0%', // Remove gaps
                    itemStyle: {
                        color: 'rgba(0, 0, 0, 0)', // Transparent bar for no data
                        borderWidth: 0,             // Remove border width
                    },
                }]
            };
            chart.setOption(options);
            this.charts.push(chart);
        },
        initChart1(){
            let title = '';
            let data = [];
            switch(this.selectedLanguage.lang){
                case 'pt':
                    title = 'Segmentação de manutenções';
                    data = [
                        { value: 1048, name: 'Corretiva',itemStyle: {color: '#dbf2f2'} },
                        { value: 735, name: 'Preventiva',itemStyle: {color: '#ffe0e6'} },
                        { value: 580, name: 'Preditiva',itemStyle: {color: '#ebe0ff'} },
                        { value: 484, name: 'Decreto-Lei 50/2005',itemStyle: {color: '#ffedcd'} },
                    ];
                    break;
                case 'en':
                   
                    break;
                case 'es':
                   
                    break;
            }
            let chart = echarts.init(this.$refs.chart[0]);
            let options = {
                title: [
                    {
                        text: title,
                        left: 'center',
                        padding:[0,0,0,10]
                    }
                ],
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: title,
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        padAngle: 5,
                        itemStyle: {
                            borderRadius: 10
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 20,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            };
            chart.setOption(options);
            this.charts.push(chart);
        }, 
        initChart2(){
            let title = '';
            let datax = []
            let datay = [];
            switch(this.selectedLanguage.lang)
            {
                case 'pt':
                    title = 'Downtine Tracking';
                    datax = ['1', '2', '3','4','5','6','7','8','9','10','11','12','13','14','15', '16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];
                    datay = [
                        {data: ['9', '45', '98','40','5','16','72','21','92','10','91','22','38','64','65', '26','17','38','69','70','21','26','13','54','35','16','77','88','59','40','21'],stack: 'total',type: 'bar',name:'Manutenção',label: {show: false,},itemStyle: {color: '#00cec9'}},
                        {data: ['20', '110', '12','15','52','23','27','44','19','30','71','42','23','14','55', '56','67','98','39','23','29','32','63','34','65','46','37','88','25','30','51'],stack: 'total',type: 'bar',name:'Técnico',label: {show: false,},itemStyle: {color: '#fdcb6e'}},
                        {data: ['34', '40', '32','30','50','45','17','32','39','60','66','32','83','24','25', '86','87','38','29','28','61','82','83','64','85','16','37','78','49','30','36'],stack: 'total',type: 'bar',name:'Setup',label: {show: false,},itemStyle: {color: '#fd79a8'}},
                        {data: ['12', '24', '30','32','28','46','72','32','95','21','54','72','53','64','55', '36','24','98','39','29','91','62','93','44','65','36','27','58','29','30','81'],stack: 'total',type: 'bar',name:'Paragem Planeada',label: {show: false,},itemStyle: {color: '#636e72'}},
                    ]
                    break;
                case 'en':
                    break;
                case 'es':
                    break;
            }
            let chart2 = echarts.init(this.$refs.chart[0]);
            let options2 = {
                title: [
                    {
                        text: title,
                        left: 'center',
                        padding:[0,0,0,10]
                    }
                ],
                legend: {
                    top: '5%',
                    left: 'center'
                },
                xAxis: {
                    type: 'category',
                    data: datax
                },
                yAxis: {
                    type: 'value'
                },
                series: datay,
                barWidth:'40%',
                tooltip: {
                    trigger: 'item',  // 'item' means tooltip shows for individual points
                    formatter: function(params)
                    {
                        return params.seriesName+': '+params.data;
                    }, // Format to display the name and value
                },

            };
            chart2.setOption(options2);
            this.charts.push(chart2);
        },
        initChart3(){
            let title = '';
            let datax = []
            let datay = [];
            switch(this.selectedLanguage.lang){
                case 'pt':
                    title = 'Custo mensal por tipo de manutenção';
                    datax = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];
                    datay = [
                    {
                    name: 'Corretiva',
                    type: 'bar',
                    barGap: 0,
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {color: '#dbf2f2'},
                    data: [320, 332, 301, 334, 390,320, 332, 301, 334, 390,320, 332, 301, 334]
                    },
                    {
                    name: 'Preventiva',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {color: '#ffe0e6'},
                    data: [220, 182, 191, 234, 290,220, 182, 191, 234, 290,220, 182, 191]
                    },
                    {
                    name: 'Preditiva',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {color: '#ebe0ff'},
                    data: [150, 232, 201, 154, 190,150, 232, 201, 154, 190,150, 232, 201]
                    },
                    {
                    name: 'Decreto-Lei 50/2005',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {color: '#ffedcd'},
                    data: [98, 77, 101, 99, 40,98, 77, 101, 99, 40,98, 77, 101]
                    }
                ]
                    break;
                case 'en':
                    
                    break;
                case 'es':
                    
                    break;
            }
            let chart3 = echarts.init(this.$refs.chart[2]);
            let options3 = {
                title: [
                    {
                        text: title,
                        left: 'center',
                        padding:[0,0,0,10]
                    }
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                xAxis: [
                    {
                    type: 'category',
                    axisTick: { show: false },
                    data: datax
                    }
                ],
                yAxis: [
                    {
                    type: 'value'
                    }
                ],
                series: datay
            };
            chart3.setOption(options3);
            this.charts.push(chart3);
        },
        async checkCache(route) {
            if ('caches' in window) {
                const cacheName = 'my-cache-v1'; 
                const cache = await caches.open(cacheName);
                const cachedResponse = await cache.match(route);
                const isCached = !!cachedResponse;
                if(!isCached) this.$parent.$parent.$parent.resetFlag();            
            }
        },
        filter(f) {
            // console.log(f)
            let target = event.target.parentElement;
            target.classList.toggle('active');

            if(target.classList.value.includes('active')){
                this.services_selected.push(f);
            }else{
                this.services_selected.splice(this.services_selected.indexOf(f),1);
            }       
        },
        getNames(){
            let names = []
            this.services_selected.forEach(element => {
                names.push(this.services.find(s => s.id == element).name);
            });
            return names.join(',');
        }, 
        async submitForm() {
            if(this.checkForm()){
                this.loading=true;
                let services_selected_names = this.getNames();

                $('#error_submit').hide();
 
                await fetch('https://cms.vivify.pt/wp-json/options/send-email-project',{
                    method:'POST',
                    headers: {
                        'Content-Type': 'application/json',  // Ensure the content type is JSON
                    },
                    body:JSON.stringify(
                        {
                            token:this.token,
                            subject:'PEDIDO DE DEMO',
                            message:'EMPRESA :'+this.empresa+"\nNOME: "+this.nome+"\nEMAIL: "+this.email+"\nMENSAGEM: "+this.mensagem+"\nSERVIÇOS: "+this.product_demo 
                        }
                    ) 
                })
                .then(res =>{
                    if(res.status==200){
                        this.success('Obrigado pelo seu contacto!','ENVIADO COM SUCESSO'); 

                        this.resetForm();
                        this.date = null;
                    }else{
                        this.error('Erro ao enviar pedido de contacto','TENTE MAIS TARDE') 

                    }
                    this.loading=false; 
                })
            } 
        },
        resetForm(){
            this.empresa=null;
            this.nome=null;
            this.telefone=null;
            this.email=null;
            this.mensagem=null;
            this.contact=null;
            // this.terms=false; 
            this.product_demo = null;
        },
        checkForm(){
            // nome, email, empresa e fale-nos, são obrigatórios
            this.errors=[];
            if(!this.validEmail(this.email))this.errors.push('email'); 
            if(this.empresa=='' || this.empresa==null)this.errors.push('empresa');
            if(this.nome=='' || this.nome==null)this.errors.push('nome'); 
            if(this.mensagem=='' || this.mensagem==null)this.errors.push('mensagem'); 
            if (!this.product_demo) this.errors.push('product_demo'); 

            if(this.errors.length>=1)return false;
            return true;
        },
        validEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        getGradientColor(index, feature) {
            const isLgScreen = window.innerWidth >= 992;
            const direction = isLgScreen ? 'to right' : 'to bottom';

            let background_color;
            let background_color_2;

            if (isLgScreen) {
                background_color = feature.background_color;
                background_color_2 = feature.background_color_2;
            } else {
                background_color = feature.background_color_2;
                background_color_2 = feature.background_color;
            }
             
            const isEvenIndex = index % 2 === 0;
            const color1 = (isEvenIndex && !isLgScreen) ? background_color : background_color_2;
            const color2 = (isEvenIndex && !isLgScreen)  ? background_color_2 : background_color;

            return `linear-gradient(${direction}, ${color1} 50%, ${color2} 50%)`;
        },


        updateTabWidth() {
            this.tab_width_1 = window.innerWidth <= 425 ? 2 : 3;
            this.tab_width_2 = window.innerWidth <= 768 ? 1 : window.innerWidth <= 992 ? 2 : 3;
        },

        updateScreenSize() {
            this.isLgScreen = window.innerWidth >= 992; // Atualiza o estado
        },
    
    }
}
</script>

<style>

</style>