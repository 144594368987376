import { createApp } from 'vue'
import App from './App.vue'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import {NTimelineItem ,NButton, create, NSpin, NProgress, NCollapse, NCollapseItem, NNotificationProvider, NTabs, NCard, NTabPane, NSlider, NCarousel, NCarouselItem, NIcon, NDatePicker, NAlert, NTimeline, NCheckbox, NSwitch } from 'naive-ui'
import { library } from "@fortawesome/fontawesome-svg-core";
import 'jquery/src/jquery.js'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createRouter, createWebHistory} from 'vue-router';
import home from './components/home.vue';
import services from './components/services/services.vue';
import industry from './components/industry/industry.vue';
import culture from './components/culture/culture.vue';
import portfolio from './components/portfolio/portfolio.vue';
import contacts from './components/contacts/contacts.vue';
import projects from './components/portfolio/project.vue';
import blog from './components/culture/blog.vue';
import blogs from './components/culture/blogs.vue';
import challenge from './components/challenge/challenge.vue';
import policy from './components/policy/policy.vue';
import terms from './components/terms/terms.vue';
import opportunities from './components/opportunities/opportunities.vue';
import cmms from './components/cmms/cmms.vue';
import iot from './components/iot/iot.vue';
import notfound from './components/not-found/notfound.vue';

// import testimonials from './components/testimonials/testimonials.vue';
import { VueReCaptcha} from 'vue-recaptcha-v3'
import { createPinia } from 'pinia';

import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'

library.add(fab)

// Import Font Awesome CSS
import '@fortawesome/fontawesome-free/css/all.min.css'

//Import VIVIFY CSS
import './assets/sass/main.scss'
import './assets/sass/bootstrap.scss'
import { createHead } from 'unhead'
import { useHead } from 'unhead'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistration().then((registration) => {
    if (!registration) {
      navigator.serviceWorker.register('/service-worker.js').then((reg) => {

      }).catch((error) => {
          console.error('Service Worker registration failed:', error);
      });
    } else {
      console.log('Service Worker already registered.');
    }
  })
}

createHead()
useHead({
  title: 'VIVIFY',
  meta: [
    { name: 'description', content: 'Desenvolvemos soluções web à medida das necessidades de cada cliente/projeto.' },
    { name: 'keywords', content: 'Project Management,Design,Web Development' },
    { name: 'author', content: 'VIVIFY' },
  ],
})

function afterNavigate() {
  // console.log('afterNavigate');
  const mobileMenu = document.getElementById('all-mobile');
    
  if (mobileMenu) {   
    const collapseHeaderActive = mobileMenu.querySelector('.n-collapse-item__header--active');
    
    if (collapseHeaderActive) { 
      // console.log('collapseHeaderActive');
      closeMobileMenu(); 
    }
  }
  try{
  // Corrige a cor do menu'
    $('.n-menu-item-content--selected').removeClass('n-menu-item-content--selected');
  }catch(e){

  }
  window.scrollTo({ top: 0, behavior: 'smooth' });  
  // window.scrollTo(0, 0); 


}

function closeMobileMenu() {  
    const mobileMenu = document.getElementById('mobileMenu');
    
    if (mobileMenu) { 
        const collapseHeader = mobileMenu.querySelector('.n-collapse-item__header-main');
          
        if (collapseHeader) {
            collapseHeader.click();
        } else {
            console.warn('Elemento .n-collapse-item__header não encontrado dentro de #mobileMenu');
        }
    } else {
        console.warn('Elemento #mobileMenu não encontrado');
    }
}

function updateCanonicalTag(url) {
  let link = document.querySelector("link[rel='canonical']");
  if (!link) {
    link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    document.head.appendChild(link);
  }
  link.setAttribute("href", url);
}
let flag = false;

async function loadRecaptchaScript(app,siteKey) {
  app.use(VueReCaptcha, {
    siteKey: siteKey,
  });
  flag = true;
}

const naive = create({
  components: [NTimelineItem,NTimeline,NButton, NSpin, NProgress, NCollapse, NCollapseItem, NNotificationProvider, NTabs, NCard, NTabPane, NSlider, NCarousel, NCarouselItem, NIcon, NCollapse, NCollapseItem, NDatePicker, NAlert, NCheckbox, NSwitch]
})

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: home },
    { path: '/services', component: services },
    { path: '/portfolio', component: portfolio },
    { path: '/industry', component: industry , meta: { requiresRecaptcha: true }},
    { path: '/culture', component: culture },
    { path: '/contacts', component: contacts , meta: { requiresRecaptcha: true }},
    { path: '/blog', component: blogs },
    { path: '/challenge', component: challenge, meta: { requiresRecaptcha: true }}, 
    { name: 'Project', path: '/project/:id', component: projects },
    { name: 'Blog', path: '/blog/:id', component: blog },
    { path: '/privacy-policy', component: policy },
    { path: '/terms-and-conditions', component: terms },
    { path: '/opportunities', component: opportunities , meta: { requiresRecaptcha: true }},
    { path: '/cmms', component: cmms , meta: { requiresRecaptcha: true }},
    { path: '/iot', component: iot , meta: { requiresRecaptcha: true }},
    { path: '/:pathMatch(.*)*', component: notfound },
  ]
});
const app = createApp(App);

router.afterEach(async(to, from) => {
  // console.log('Navegação de rota ocorreu de:', from.fullPath, 'para:', to.fullPath);
  afterNavigate();
  updateCanonicalTag(window.location.origin+to.path);
  if (to.meta.requiresRecaptcha) {
    try {
      if(flag==false)await loadRecaptchaScript(app,'6LfwryEqAAAAAABbDc4AXRTYMSrwQpxUyb3savsI');
      // console.log('LOAD RECAPTCHA')
    } catch (error) {
    }
  }
})

const pinia = createPinia();

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.use(naive);
app.use(pinia);
app.use(VueCookieComply);
//app.use(VueReCaptcha, { siteKey: '6LfwryEqAAAAAABbDc4AXRTYMSrwQpxUyb3savsI', loaderOptions:{} } )
app.mount('#app')
