<template>
    <div>
        <div class="services-banner">  
            <div class="blue-filter"></div>
            <div class="container">
                <div class="row services py-5">
                    <div class="col-12 col-sm-4 title">
                        404
                    </div>
                    <div class="col-8 d-none d-sm-block">
                    </div>
                    <div class="col-12 col-md-6 text" style="margin-top:0px">
                        PAGE NOT FOUND
                    </div>
                    <div class="col-6 d-none d-sm-block">
                    </div>
                </div>
            </div>
            <img loading="lazy"  class="d-none d-sm-block bk_v_2" src="../../assets/green_logo.png" alt="">
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="blue-button">
                    <RouterLink to="/">
                        HOME
                    </RouterLink>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    data(){
        return{
        }
    },

    setup(){
		
	},

    created(){
        this.$parent.$parent.$parent.addtoPercentage(33);

    },
    methods:{
        
    }
}
</script>

<style>

</style>