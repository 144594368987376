<template> 
    <div class="container my-5">
        <div class="testimonials-title" v-html="title"></div>
        <n-carousel :slides-per-view="tab_width_3" autoplay draggable :show-dots="true">
            <n-carousel-item 
                class="testimonials py-5" 
                v-for="(t, count) in testimonials" 
                :key="count" 
                style="position: relative; border-radius: 20px;"
            >
                <div class="testimonials">
                    <div class="row">
                        <div class="col-12">
                            <div class="image">
                                <div class="opacity"></div>
                                <img loading="lazy" :src="t.image" alt="Testimonial Image">
                            </div>
                        </div>
                        <div class="col-12">
                            <div :class="'pt-4 py-2 px-4 title-' + (count % 2 === 1 ? '2' : '1')">
                                {{ t.title }}
                            </div>
                        </div>
                        <div class="col-12 content-col">
                            <div style="height: 100%;" :class="'py-2 px-4 testimonials-text content-' + (count % 2 === 1 ? '2' : '1')">
                                {{ t.content }}
                            </div>
                        </div>
                        <div class="col-12">
                            <div :class="'pb-4 py-2 px-4 footer-' + (count % 2 === 1 ? '2' : '1')">
                                {{ t.name }}
                                <br>
                                <div class="positon-text">
                                    {{ t.position }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </n-carousel-item>

            <template #dots="{ total, currentIndex, to }">
                <ul class="custom-dots" style="position: relative; top: -5px;">
                    <li
                        v-for="index in total"
                        :key="index"
                        :class="{ ['is-active']: currentIndex === index - 1 }"
                        @click="to(index - 1)"
                    ></li>
                </ul>
            </template>
        </n-carousel>
    </div>
</template>

<script>

import { computed } from "vue"; 
import { useLanguageStore } from '../stores/language';

export default {
    data() {
        return {
            testimonials: [],
            title: '',
            tab_width_3: window.innerWidth <= 768 ? 1 : window.innerWidth <= 1200 ? 2 : 3,
        };
    },
    
    setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };
        return { selectedLanguage, changeLanguage };
	},
    
    async created() {
        await fetch('https://cms.vivify.pt/wp-json/options/home'+this.selectedLanguage.lang_url)
            .then(res => res.json())
            .then(res => {
                const data = res.home[0];
                this.testimonials = data.testimonials[0].testimony;
                this.title = data.testimonials[0].title;

                
                // const description = document.getElementById('meta-description');
                // const keywords = document.getElementById('meta-keywords');

                // if (description) {
                //     description.setAttribute('content', res.home[0].metadescription || '');  
                // }
                // if (keywords) {
                //     keywords.setAttribute('content', res.home[0].metakeywords || ''); 
                // }

            });
    },
    mounted() {
        window.addEventListener("resize", this.updateTabWidth);
    },
    unmounted() {
        window.removeEventListener("resize", this.updateTabWidth);
    },
    methods: {
        updateTabWidth() {
            this.tab_width_3 = window.innerWidth <= 768 ? 1 : window.innerWidth <= 1199 ? 2 : 3;
        },
    },

    
};
</script>

<style>

</style>
