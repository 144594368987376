<template>
  <footer>
    <div v-if="done" class="container">
        <div class="row">
			<div class="col-12 col-sm-12 col-lg-3">
				<div class="footer-pattern text-center text-lg-start">
					<img style="width: 100%;" loading="lazy" src="../../assets/pattern.svg" alt="">
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3 px-5 px-lg-3 mt-4 mt-lg-0">
				<div class="menu-footer-div-1">
					<div v-for="m in menu" class="row">
						<!-- <div class="col-12 menu-footer mb-3">{{ m.title }}</div> -->
						<RouterLink class="col-12 menu-footer mb-3" :to="m.link"> {{ m.title }}</RouterLink>
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3 px-5 px-lg-3 mt-4 mt-lg-0">
				<div class="menu-footer-div-2">
					<!-- <div v-for="l in links" class="row">
						<RouterLink class="col-12 menu-footer mb-3" :to="l.link"> {{ l.title }}</RouterLink>
					</div> -->

					<div v-for="l in links" class="row" :key="l.title">
						<template v-if="isExternalLink(l.link)">
							<a class="col-12 menu-footer mb-3" :href="l.link" target="_blank" rel="noopener noreferrer">
							{{ l.title }}
							</a>
						</template>
						<template v-else>
							<RouterLink class="col-12 menu-footer mb-3" :to="l.link">
							{{ l.title }}
							</RouterLink>
						</template>
					</div>


				</div>
			</div> 
			<div class="col-12 col-sm-12 col-lg-3 px-5 px-lg-0">

				
				<div class="footer-icons">
					<a class="icon-link1"  v-for="s in socials" :href="s.link" target="_blank">
						<font-awesome-icon class="icon" :icon="[s.icon.split(',')[0],s.icon.split(',')[1]]" :class="{ hidden: !(s.visivel) }"  />
					</a>
				</div>
				<!-- <div class="logo-ppr-div text-center text-lg-start align-content-center">
					<img loading="lazy" class="logo-ppr w-100 " :src="logo_prr" alt="">
				</div> -->
			</div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="footer-bar"></div>
            </div>
        </div>
        <div class="row">
			<div class="col-12 col-md-6 text-center text-lg-start">
				<div class="footer-copyright"> 
					<span>© {{ year }} {{ rights_text }} </span>
				</div>
			</div>
			<div class="col-12 col-md-6 text-center text-lg-end">
				<!-- <div class="footer-icons">
					<a class="icon-link1"  v-for="s in socials" :href="s.link" target="_blank">
						<font-awesome-icon class="icon" :icon="[s.icon.split(',')[0],s.icon.split(',')[1]]" :class="{ hidden: !(s.visivel) }"  />
					</a>
				</div> -->
				
				<div class="logo-ppr-div">
					<img loading="lazy" class="logo-ppr w-100 " :src="logo_prr" alt="">
				</div>

			</div>
		</div>
    </div>
  </footer>
</template>
  
<script>
import { computed } from 'vue';
import { useLanguageStore } from '../stores/language';
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);

export default {
    data(){
      return{
			year: new Date().getFullYear(),
			socials:[],
			links:[],
			contacts:[],
			menu:[],
			newsletter:[],

			rights_text:null,
			
			done:false,
			logo_prr:null
      }
    },
	
	setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
		

		const changeLanguage = (lang) => {
			languageStore.setLanguage(lang);
		};
		return { selectedLanguage, changeLanguage };
	},
	
    async created(){
		await fetch('https://cms.vivify.pt/wp-json/options/footer'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
			.then(res => res.json()) 
			.then(res =>{
				this.socials=res.socials;
				this.links=res.links;
				this.contacts=res.contacts;
				this.menu=res.menu_footer;
				this.newsletter=res.newsletter[0];
				this.logo_prr=res.logo_prr;
				this.$parent.$parent.addtoPercentage(33);

				this.rights_text=res.rights_text;

				this.done = true;
			})
    },

	methods: { 
		isExternalLink(link) {
			return /^(http|https):\/\//.test(link);
		},
	},

	
  }
  </script>
  
  <style>
  
  </style>